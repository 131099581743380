import GreenTick from '../../components/GreenTick';
import Grid from '../../components/Grid';
import Section from '../../components/Section';
import AfterLoggedIn from '../../components/login/AfterLoggedIn';
import EmailPanel from '../../components/login/EmailPanel';
import { useModal } from '../../components/modal/ModalContext';
import { useTheme } from '../../components/theme/Theme';
import { resources, types } from '../../constants';
import { useBackendApi } from '../../helpers/backendHelper';
import { useDiscordHelper } from '../../helpers/discordHelper';
import { useLoginHelper } from '../../helpers/login/loginHelper';
import { useTelegramHelper } from '../../helpers/telegramHelper';
import { useTwitterHelper } from '../../helpers/twitterHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import './Settings.scss';

const SocialLoginItem = ({ item }) => {
  const backendApi = useBackendApi();
  const [{ setUser }] = useGlobalState();

  const disconnect = async () => {
    try {
      const promise = backendApi.user.disconnectAccount({ loginVia: item.id });
      const newUser = await alert.promise(promise, {
        pending: `Disconnecting`,
        success: `disconnected`,
        error: `Could not disconnect account`,
      });
      setUser(newUser);
    } catch (e) {}
  };

  const onClick = () => {
    if (item.value) return disconnect();
    item.onConnect();
  };

  return (
    <div className='SocialLoginItem-container'>
      <img src={item.icon} />
      <h1>{item.value || item.id}</h1>
      {item.value ? <GreenTick /> : <></>}
      <button className={item.value ? 'disconnect' : 'primary'} onClick={onClick}>
        {item.value ? 'Disconnect' : 'Connect'}
      </button>
    </div>
  );
};

const Settings = () => {
  const [theme, toggleTheme] = useTheme();
  const [{ user, mercleCommunity }] = useGlobalState();
  const [{ openModal }] = useModal();
  const { userLogout } = useLoginHelper();
  const discordHelper = useDiscordHelper(mercleCommunity);
  const twitterHelper = useTwitterHelper(mercleCommunity);
  const telegramHelper = useTelegramHelper(mercleCommunity);

  const socials = [
    {
      id: types.loginVia.email,
      value: user?.email,
      icon: 'https://dskhnex0aho1n.cloudfront.net/public/events/email.svg',
      onConnect: () => openModal({ content: <EmailPanel /> }),
    },
    {
      id: types.loginVia.twitter,
      value: user?.twitter?.username,
      icon: resources?.img?.twitter_no_circle,
      onConnect: () => twitterHelper.loginTwitter(window.location.href),
    },
    {
      id: types.loginVia.telegram,
      value: user?.telegram?.id,
      icon: 'https://dskhnex0aho1n.cloudfront.net/public/events/telegram.svg',
      onConnect: () => telegramHelper.loginTelegram(resources.links.telegram.mercleGroup),
    },
    {
      id: types.loginVia.discord,
      value: user?.discord?.id,
      icon: 'https://dskhnex0aho1n.cloudfront.net/public/events/discord.svg',
      onConnect: () => discordHelper.loginDiscord(window.location.href),
    },
  ];

  return (
    <AfterLoggedIn>
      <Section className='Settings-container' title='My Settings'>
        <Grid className='SocialLogins-container' title='Socials' columns={2}>
          {socials.map((social, i) => (
            <SocialLoginItem key={i} item={social} />
          ))}
        </Grid>
        <Grid className='Misc-container' columns={8}>
          <button onClick={toggleTheme}>Toggle Theme</button>
          <button className='logout' onClick={userLogout}>
            Logout
          </button>
        </Grid>
      </Section>
    </AfterLoggedIn>
  );
};

export default Settings;
