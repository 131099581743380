import { ethers } from 'ethers';
import { useContext } from 'react';
import { walletContext } from 'walletconnector';
import { dateMillis, deployed, id, rpc, types } from '../constants';
import utils from '../utils';
import { useBackendApi } from './backendHelper';
import { cacheCall } from './cacheHelper';

let MembershipNFTAbi;
let MembershipNFTFactoryAbi;
let TokenRewardDistributorAbi;
let Erc20Abi;
let MintWithClaimAbi;
let MwcRewardNftAbi;
let RafflePickerAbi;
let ReferralRewardAbi;

fetch('https://dskhnex0aho1n.cloudfront.net/public/solidity/abi/MembershipNFTV2_1.json')
  .then(abi => abi.json())
  .then(abi => {
    MembershipNFTAbi = abi;
  });

fetch('https://dskhnex0aho1n.cloudfront.net/public/solidity/abi/ReferralRewardDistributor.json')
  .then(abi => abi.json())
  .then(abi => {
    ReferralRewardAbi = abi;
  });

fetch('https://dskhnex0aho1n.cloudfront.net/public/solidity/abi/MembershipNFTFactoryV2_1.json')
  .then(abi => abi.json())
  .then(abi => {
    MembershipNFTFactoryAbi = abi;
  });
fetch('https://dskhnex0aho1n.cloudfront.net/public/solidity/abi/TokenRewardDistributor.json')
  .then(abi => abi.json())
  .then(abi => {
    TokenRewardDistributorAbi = abi;
  });
fetch('https://dskhnex0aho1n.cloudfront.net/public/solidity/abi/Erc20Abi.json')
  .then(abi => abi.json())
  .then(abi => {
    Erc20Abi = abi;
  });
fetch('https://dskhnex0aho1n.cloudfront.net/public/solidity/abi/MintWithClaimV2_1.json')
  .then(abi => abi.json())
  .then(abi => {
    MintWithClaimAbi = abi;
  });
fetch('https://dskhnex0aho1n.cloudfront.net/public/solidity/abi/MwcRewardNftAbi.json')
  .then(abi => abi.json())
  .then(abi => {
    MwcRewardNftAbi = abi;
  });

fetch('https://dskhnex0aho1n.cloudfront.net/public/solidity/abi/RafflePickerAbi.json')
  .then(abi => abi.json())
  .then(abi => {
    RafflePickerAbi = abi;
  });

export const useEthersHelper = () => {
  const { getSigner, changeNetwork, getChainId, getUserAddress, connect } = useContext(walletContext);
  const backendApi = useBackendApi();
  const walletConnect = async connectorId => {
    return await connect(connectorId);
  };
  const getProvider = chainId => {
    if (!window.providers) window.providers = {};
    if (window.providers[chainId]) return window.providers[chainId];

    const _rpc = rpc[chainId] || utils.getChainDetails(chainId).rpcUrls.default.http[0];
    window.providers[chainId] = new ethers.JsonRpcProvider(_rpc, chainId, { staticNetwork: true });
    return window.providers[chainId];
  };

  const getMembershipNFT = (tokenAddress, signer = getSigner()) => {
    return new ethers.Contract(tokenAddress, MembershipNFTAbi, signer);
  };

  const getMintWithClaim = (chainId, signer = getSigner()) => {
    return new ethers.Contract(deployed.MintWithClaim[chainId], MintWithClaimAbi, signer);
  };

  const getMintWithClaimOld = (chainId, signer = getSigner()) => {
    return new ethers.Contract(deployed.oldMintWithClaim[chainId], MintWithClaimAbi, signer);
  };

  const getReferralRewardDistributor = (address, signer = getSigner()) => {
    return new ethers.Contract(address, ReferralRewardAbi, signer);
  };

  const getRafflePicker = (rafflePickerAddress, signer = getSigner()) => {
    return new ethers.Contract(rafflePickerAddress, RafflePickerAbi, signer);
  };

  const getErc20Contract = async (tokenAddress, chainId, requireSigner) => {
    return await _getContract(tokenAddress, chainId, Erc20Abi, requireSigner);
  };

  const _getContract = async (contractAddress, chainId, abi, requireSigner = false) => {
    if (requireSigner) {
      await changeNetwork(chainId);
    }

    const provider = requireSigner ? getSigner() : getProvider(chainId);
    return new ethers.Contract(contractAddress, abi, provider);
  };

  const getDecimals = async (tokenAddress, chainId) => _cachedTokenFunc(id.storage.tokenDecimals(tokenAddress, chainId), tokenAddress, chainId, 'decimals');

  const getTokenName = async (tokenAddress, chainId) => _cachedTokenFunc(id.storage.tokenSymbol(tokenAddress, chainId), tokenAddress, chainId, 'name');

  const getTokenSymbol = async (tokenAddress, chainId) => _cachedTokenFunc(id.storage.tokenSymbol(tokenAddress, chainId), tokenAddress, chainId, 'symbol');

  const _cachedTokenFunc = async (cacheKey, tokenAddress, chainId, functionName) => {
    try {
      const token = await _getContract(tokenAddress, chainId, Erc20Abi);
      return await cacheCall(cacheKey, token[functionName], dateMillis.year_1);
    } catch (e) {}
    return undefined;
  };

  /**
   * @returns tokenId
   */
  const getNftProfileTokenId = async (communityName, ownerAddress, tokenAddress, chainId, refreshCache = false) => {
    const getNftTokenId = async () => {
      if (chainId == types.MercleOffchain) {
        const token = await backendApi.communities.getOffchainTokenDetails(communityName, tokenAddress);
        // token.tokenUri is also in response if required
        return { tokenId: token.tokenId };
      }
      const token = await backendApi.communities.getOnchainTokenDetails(communityName, tokenAddress, chainId);
      //   const metadataUrl = await nft.tokenURI(tokenId);
      return { tokenId: token.tokenId };
    };

    const { tokenId, metadataUrl } = await cacheCall(id.storage.nftProfile(ownerAddress, tokenAddress, chainId), getNftTokenId, dateMillis.month_1, refreshCache);

    if (!tokenId) throw new Error('Nft Profile found');

    return { tokenId, metadataUrl };
  };

  const parseRafflePickerTxn = async (txnHash, chainId) => {
    const txn = await getProvider(chainId).getTransaction(txnHash);
    const iter = new ethers.Interface(RafflePickerAbi);
    return { parsedTxn: iter.parseTransaction(txn), txn };
  };
  const getMembershipNFTFactory = (chainId, signer) => {
    const contractAddress = deployed.mercle.MembershipNFTFactory[chainId];
    if (!contractAddress) {
      alert.error('Network not supported');
      throw new Error('Network not supported');
    }
    return new ethers.Contract(contractAddress, MembershipNFTFactoryAbi, signer);
  };

  const deployErc721Token = async (
    {
      communityId,
      chainId,
      tokenType,
      creator,
      name,
      symbol,
      description,
      isCampaign,
      isProfile,
      profileData, // might be null for non profile contracts
      isOpenMint = false,
      isTradable = false,
      saveAs = null,
    },
    signer,
  ) => {
    // todo: wait for network change to chainId
    // const isSuccess = await loginHelper.requestChangeNetwork(chainId);
    // if (!isSuccess) throw new Error("Chain not added to wallet");
    const factory = getMembershipNFTFactory(chainId, signer);
    const token = await factory.deployContract(name, symbol, creator, isOpenMint, isTradable);

    await backendApi.communities.tokens.trackTokenCreation({
      txnHash: token.hash,
      payload: {
        creator,
        chainId,
        tokenType,
        isCampaign,
        isProfile,
        campaign: profileData?.campaign,
        saveAs,
      },
    });
    alert.info('Deploy NFT token requested');
    const res = await token.wait();
    alert.success('NFT Token has been deployed');
    return res.logs[1].address;
  };

  return {
    getProvider,
    getChainId,
    getSigner,
    changeNetwork,
    deployErc721Token,
    getMembershipNFT,
    getMintWithClaim,
    getRafflePicker,
    getDecimals,
    getTokenName,
    getTokenSymbol,
    getErc20Contract,
    getReferralRewardDistributor,
    getUserAddress,
    getNftProfileTokenId,
    parseRafflePickerTxn,
    walletConnect,
    getMintWithClaimOld,
  };
};

export default {
  useEthersHelper,
};
