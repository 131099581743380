import { ethers } from 'ethers';
import { id, types } from '../constants';
import utils from '../utils';
import { useBackendApi, useGelatoApi } from './backendHelper';
import { useEthersHelper } from './ethersHelper';

export const useTokenHelper = () => {
  const { getUserAddress, getMembershipNFT, getSigner, changeNetwork, getMintWithClaim, getMintWithClaimOld, getProvider } = useEthersHelper();
  const backendApi = useBackendApi();
  const gelatoApi = useGelatoApi();
  const ethersHelper = useEthersHelper();

  const getTxnFromGelato = async (taskId, chainId) => {
    const provider = getProvider(chainId);
    for (let i = 0; i < 20; i++) {
      const txnHash = (await gelatoApi.getTxn(taskId))?.task?.transactionHash;
      if (txnHash) {
        const txn = await provider.getTransaction(txnHash);
        if (txn) return txn;
      }

      await new Promise((res, rej) => {
        setTimeout(res, 10000);
      });
    }

    throw new Error('Could not get transaction');
  };

  const mintProfile = async (campaign, username, setLoadingMessage, referralCode) => {
    try {
      window.mixpanel?.track?.('MintCard', {
        url: window.location.href,
        func: 'mintProfile',
        name: username,
      });
      // await changeNetwork(campaign.chainId);
      setLoadingMessage('Uploading your NFT Metadata to IPFS');

      const inventoryConfig = campaign?.template?.config?.selected?.tokenId
        ? {
            baseNftTokenId: campaign?.template?.config?.selected?.tokenId,
            assetIds: campaign?.template?.config?.selected?.assetIds,
          }
        : undefined;

      const offChainToOnChain = campaign.chainId === types.MercleOffchain && campaign?.onChainToken?.tokenAddr;

      const txnResponse = await backendApi.communities.tokens.getMintToken(
        campaign.tokenAddr,
        campaign.name,
        getUserAddress(),
        username,
        referralCode,
        inventoryConfig ? JSON.stringify(inventoryConfig) : undefined,
        offChainToOnChain,
      );

      let txn;
      if (txnResponse.txnHash) {
        // await for txn
        setLoadingMessage('Please wait while we Mint your NFT');

        txn = await getTxnFromGelato(txnResponse.txnHash, campaign.chainId);
      } else {
        const dynamicPrice = campaign?.mintFeeConfig?.dynamicPriceOnUsername;
        setLoadingMessage('Please approve the transaction');
        const chainId = offChainToOnChain ? campaign?.onChainToken?.chainId : campaign.chainId;
        await changeNetwork(chainId);

        const useOldMintWithClaim = campaign.tokenAddr === '0x214762A91fd5Fcc4Ab0af6e615b3238D2b71EED5' && +campaign.chainId === 10;
        const nft = useOldMintWithClaim ? getMintWithClaimOld(chainId) : getMintWithClaim(chainId);
        txn = await nft.mintNFTClaim(txnResponse.mintClaim.domain, txnResponse.mintClaim.message, txnResponse.mintClaim.sign, {
          value: ethers
            .parseUnits(utils.getMintingFeeFromName(username, dynamicPrice?.minFee, dynamicPrice?.maxFee)?.toString() || campaign?.mintFeeConfig?.amount?.toString() || '0')
            .toString(),
        });
        setLoadingMessage('Please wait while we Mint your NFT');
      }
      console.log(txn);
      const receipt = await txn.wait();
      console.log(receipt);

      window.mixpanel?.track?.('MintCard', {
        url: window.location.href,
        func: 'mintProfile',
        name: username,
        success: true,
      });

      localStorage.removeItem(id.storage.referralCode);
      localStorage.removeItem(`cache_${id.storage.mintFeeTxnHash(campaign.commId)}`);

      setLoadingMessage('NFT Mint complete');
      alert.success('Mint complete');
    } catch (error) {
      if (error.message?.includes?.('user reject')) {
        alert.error('Transaction Rejected');
      } else {
        alert.error('Something went wrong. Please try again after sometime');
      }
      console.error(error, 'error mintProfile');
      throw error;
    }
  };

  const getTokenContract = (tokenAddress, chainId, biconomyProvider) => {
    return getMembershipNFT(tokenAddress, biconomyProvider || getSigner());
  };

  return { getTokenContract, mintProfile };
};

export const useTokenCreation = () => {
  const ethersHelper = useEthersHelper();

  const createERC721 = async ({
    communityId,
    chainId,
    name,
    description,
    symbol,
    walletDetails,
    tokenType,
    isCampaign = true,
    isProfile = false,
    isOpenMint = false,
    isTradable = false,
    profileData = {
      nftLayers: [],
      matchAny: true,
      whitelist: [],
      campaign: {},
    },
    saveAs = null,
  }) => {
    await ethersHelper.walletConnect();

    if (!name) return alert.error('Token name missing');
    if (!symbol) return alert.error('Token symbol missing');
    if (!walletDetails) return alert.error('Token treasury missing');
    if (!description) return alert.error('Token description missing');
    if (!chainId) return alert.error('Deployment Chain missing');

    const data = {
      communityId,
      chainId,
      tokenType,
      creator: walletDetails.address,
      name,
      symbol,
      description,
      isCampaign, // todo: we might have different types of NFT later on
      isProfile,
      profileData,
      isOpenMint,
      isTradable,
      saveAs,
    };

    const tokenAddress = await ethersHelper.deployErc721Token(data, ethersHelper.getSigner());
    return tokenAddress;
  };

  return {
    createERC721,
  };
};

export const useToken = () => {
  const ethersHelper = useEthersHelper();

  const getTokenDetails = (tokenAddress, chainId) => {
    // const provider = getProviderCached(chainId);
    return ethersHelper.getMembershipNFT(tokenAddress, ethersHelper.getSigner());
  };

  return { getTokenDetails };
};

const obj = {
  useTokenCreation,
  useTokenHelper,
};

export default obj;
