import ButtonApi from '../../../../components/ButtonApi';
import Icons from '../../../../components/icons/Icons';
import { useMint } from './MintNftProvider';

const Step1 = () => {
  const [{ isDisabled, chainName, onNext }] = useMint();

  return (
    <>
      <p className='mintcaption'>
        <Icons icon={'info'} /> <i>Mint on {chainName}</i>
      </p>
      <ButtonApi className='primary' onClick={onNext} disabled={isDisabled}>
        Mint Now
      </ButtonApi>
    </>
  );
};

export default Step1;
