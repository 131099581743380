import { useContext } from 'react';
import { walletContext } from 'walletconnector';
import { id, types } from '../constants';
import { useGlobalState } from '../state/GlobalStateProvider';
import { useBackendApi, useGelatoApi } from './backendHelper';
import { useEthersHelper } from './ethersHelper';

export const useMintHelper = () => {
  const [{ user }] = useGlobalState();
  const { changeNetwork } = useContext(walletContext);
  const ethersHelper = useEthersHelper();
  const backendApi = useBackendApi();
  const gelatoApi = useGelatoApi();

  const setUsername = async username => {
    // todo: make this atomic later
    const response = await backendApi.user.checkUserName(username);
    if (!response?.isAvailable) {
      alert.error('Username is not available');
      return false;
    }
    await backendApi.user.setUserName({ user: { name: username } });
    return true;
  };

  const updateUserInfo = async ({ firstName, lastName }) => {
    return await backendApi.user.setUserName({ user: { firstName, lastName } });
  };

  const mint = async (communityName, campaign, username, referralCode, refreshNftProfile) => {
    const alertId = alert.loading('Verifying your details');
    try {
      window.mixpanel?.track?.('MintCard', {
        url: window.location.href,
        func: 'mintProfile',
        name: username,
      });

      alert.update(alertId, { render: 'Uploading your NFT to IPFS', isLoading: true });

      const offChainToOnChain = campaign.chainId === types.MercleOffchain && campaign?.onChainToken?.tokenAddr;
      const txnResponse = await backendApi.communities.mintToken(communityName, campaign.tokenAddr, campaign.name, user.address, username, referralCode, offChainToOnChain);
      let txn;
      if (txnResponse?.txnHash) {
        // await for txn
        alert.update(alertId, { render: 'Please wait while we mint your NFT', isLoading: true });

        txn = await _getTxnFromGelato(txnResponse.txnHash, campaign.chainId);
      } else {
        alert.update(alertId, { render: 'Please approve the transaction', isLoading: true });
        const mintFee = txnResponse.mintClaim.message.fee;
        const chainId = offChainToOnChain ? campaign?.onChainToken?.chainId : campaign.chainId;

        await changeNetwork(chainId);
        const useOldMintWithClaim = campaign.tokenAddr === '0x214762A91fd5Fcc4Ab0af6e615b3238D2b71EED5' && +campaign.chainId === 10;
        const nft = useOldMintWithClaim ? ethersHelper.getMintWithClaimOld(chainId) : ethersHelper.getMintWithClaim(chainId);
        txn = await nft.mintNFTClaim(txnResponse.mintClaim.domain, txnResponse.mintClaim.message, txnResponse.mintClaim.sign, {
          value: mintFee,
        });
      }
      alert.update(alertId, { render: 'Please wait while we mint your NFT', isLoading: true });

      const receipt = await txn.wait();
      console.log(receipt);

      alert.update(alertId, { render: 'Loading your profile', isLoading: true });
      await refreshNftProfile(true);

      window.mixpanel?.track?.('MintCard', {
        url: window.location.href,
        func: 'mintProfile',
        name: username,
        success: true,
      });

      localStorage.removeItem(id.storage.referralCode);
      localStorage.removeItem(`cache_${id.storage.mintFeeTxnHash(campaign.commId)}`);

      alert.update(alertId, {
        render: 'Mint complete',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    } catch (error) {
      const alertConfig = {
        render: 'Transaction rejected',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      };

      if (error.message?.includes?.('user reject')) alertConfig.render = 'Transaction rejected';
      else if (error?.message?.includes?.('insufficient funds')) alertConfig.render = 'Insufficient Balance';
      else if (error?.message?.includes?.('estimateGas')) alertConfig.render = 'Gas estimation failed. Please ensure you have sufficient balance or try again after few seconds.';
      else if (error?.message != '400') alertConfig.render = error?.message;
      else alertConfig.render = 'Something went wrong. Please try again after sometime';

      alert.update(alertId, alertConfig);
      console.error('error mintProfile', error);
      throw error;
    }
  };

  const _getTxnFromGelato = async (taskId, chainId) => {
    const provider = ethersHelper.getProvider(chainId);
    for (let i = 0; i < 20; i++) {
      const txnHash = (await gelatoApi.getTxn(taskId))?.task?.transactionHash;
      if (txnHash) {
        const txn = await provider.getTransaction(txnHash);
        if (txn) return txn;
      }

      await new Promise((res, rej) => {
        setTimeout(res, 10000);
      });
    }

    throw new Error('Could not get transaction');
  };

  return { setUsername, mint, updateUserInfo };
};

export default { useMintHelper };
