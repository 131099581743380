import { useEffect, useState } from 'react';
import { createContainer } from 'react-tracked';
import { id } from '../../../../constants';
import { useMintHelper } from '../../../../helpers/mintHelper';
import utils from '../../../../utils';
import { useEvent } from '../EventProvider';
import SetUsername from './SetUsername';
import Step1 from './Step1';
import StepLast from './StepLast';

const useMintState = () => {
  const [state, setState] = useState({});
  const [{ communityName, campaign, nftProfile, refreshNftProfile }] = useEvent();
  const mintHelper = useMintHelper();

  const _getDynamicFee = (name, minFee, maxFee, exponentialValue) => {
    const len = name?.length;
    if (!minFee || !maxFee || !exponentialValue) return;
    if (!len) return maxFee;

    const round = Math.max(minFee?.toString()?.split('.')?.[1]?.length || 0, 2);
    return Math.max(maxFee * Math.exp(exponentialValue * (len - 1)), minFee).toFixed(round);
  };

  const suffix = campaign?.usernameSuffix;

  const dynamicFee = campaign?.mintFeeConfig?.dynamicPriceOnUsername;
  const mintFee = dynamicFee
    ? _getDynamicFee(state.username, dynamicFee?.minFee, dynamicFee?.maxFee, dynamicFee?.exponential) || campaign?.mintFeeConfig?.amount || 0
    : campaign?.mintFeeConfig?.amount;

  const chainDetails = utils.getChainDetails(campaign?.chainId);
 
  const chainName = chainDetails?.name;
  const feeToken = chainDetails?.nativeCurrency?.symbol;
  const isDisabled = (nftProfile !== null) || campaign?.hasEnded;

  const usernameFull = `${state.username}.${suffix}`;

  /**
   * todo: handle email verification if email is not connected, also check if is whitelisted
   */

  useEffect(() => {
    const steps = [<Step1 />, <SetUsername />, <StepLast />];
    const step = 0;

    setState(s => ({ ...s, steps, step }));
  }, []);

  useEffect(() => {
    window.addEventListener('logout', logout);
  }, []);

  const onNext = () => {
    if (isDisabled) return alert.error('Nft profile already exists');

    setState(s => ({ ...s, step: Math.min(s.step + 1, s.steps.length - 1) }));
  };

  const setUsername = (t) => {
    const value = t.target.value;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
    const limitedValue = filteredValue.slice(0, 16);
    setState((s) => ({ ...s, username: limitedValue }));
  };
  

  const saveUsername = async () => {
    if (!state.username) return alert.error('Name required');

    if (!(await mintHelper.setUsername(usernameFull))) return;

    onNext();
  };

  const mint = () => {
    if (!state.username) return alert.error('Username missing');
    // perform validations and then proceed with minting
    return mintHelper.mint(communityName, campaign, usernameFull, localStorage.getItem(id.storage.referralCode), refreshNftProfile);
  };

  const logout = () => {
    setState(s => ({ ...s, username: undefined, step: 0 }));
  };

  return [{ isDisabled, chainName, suffix, mintFee, feeToken, ...state, setUsername, saveUsername, mint, onNext }, setState];
};

const { Provider, useTracked } = createContainer(() => useMintState());

export const useMint = useTracked;
export const MintProvider = ({ children }) => <Provider>{children}</Provider>;
export default MintProvider;
