import { Link } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { useBackendApi } from '../../helpers/backendHelper';
import { useGlobalState } from '../../state/GlobalStateProvider';
import Petcat from '../PetCat/Petcat';
import Icons from '../icons/Icons';
import LoginButton from '../login/LoginButton';
import { useModal } from '../modal/ModalContext';
import { useTheme } from '../theme/Theme';
import ToggleTheme from '../toggle/ToggleTheme';
import './Navbar.scss';
import ReferalModal from './ReferalModal';
import UserDropdown from './UserDropdown';

const Navbar = () => {
  const [theme, toggleTheme] = useTheme();
  const { width } = useWindowSize();
  const [{ openModal, closeModal }] = useModal();
  const [{ user }] = useGlobalState();
  const backendHelper = useBackendApi();

  const hamburgerClick = () => {
    openModal({
      className: 'Hamburger-container',
      content: (
        <>
          <Link to='/' onClick={closeModal}>
            <Icons icon={'home'} /> Home
          </Link>
          <Link to='/raffles' onClick={closeModal}>
            <Icons icon={'raffle'} /> Raffles
          </Link>
          <Link to='/mbadges' onClick={closeModal}>
            <Icons icon={'star'} /> MBadges
          </Link>
          {/* <Link to="/og-nft" onClick={closeModal}>
            <Icons icon={"star"} /> OG NFT
          </Link> */}
           <Link className='menu' to='/claim'>
           <Icons icon={'star'} />  Claim
            </Link>
          {/* <a href='https://mercle.xyz' target='_blank'>
            <Icons icon={'info'} /> About
          </a> */}
        </>
      ),
    });
  };
  async function getLink() {
    try {
      const url = window.location.href;
      console.log(url);
      let code = await backendHelper.user.getReferralCode(url);
      return `${window.location.host}/r/${code?.code}`;
    } catch (error) {
      console.log(error);
    }
  }
  const handleReferralClick = async () => {
    let link = await getLink();

    return openModal({
      content: <ReferalModal closeModal={closeModal} link={link} referalLink={link} />,
    });
  };
  //communityReferral !== null && (communityReferral || mercleReferral)

  return (
    <section className='Navbar-container'>
      <div className='left'>
        {width <= 600 ? (
          <button className='hamburger' onClick={hamburgerClick}>
            ☰
          </button>
        ) : (
          <></>
        )}
        <Link className='logo' to='/'>
          <img src={theme == 'light' ? '/logo192.png' : '/logo-white.svg'} />
        </Link>
        <Link className='logoName' to='/'>
          mercle
        </Link>
        {width <= 600 ? (
          <></>
        ) : (
          <>
            <Link className='menu' to='/'>
              Home
            </Link>
            <Link className='menu' to='/raffles'>
              Raffles
            </Link>
            <Link className='menu' to='/mbadges'>
              MBadges
            </Link>
            {/* <Link className='menu' to='/og-nft'>
              OG NFT
            </Link> */}
            <Link className='menu' to='/claim'>
              Claim
            </Link>
            {/* <a className='menu' href='https://mercle.xyz' target='_blank'>
              About
            </a> */}
          </>
        )}
      </div>
      <div className='right'>
        <Petcat />
        <ToggleTheme onChange={toggleTheme} checked={theme === 'dark'} />

        {user ? (
          <button className='referral-container' onClick={handleReferralClick} title='Refer a friend'>
            <Icons icon={'referral'} size={25.5} />
            {width <= 600 ? <></> : <span> Referral</span>}
          </button>
        ) : (
          <></>
        )}
        <LoginButton content={<UserDropdown />} />
      </div>
    </section>
  );
};

export default Navbar;
